import Vue from "vue";
import store, { actions } from "@/components/molecules/masthead/store";

import MastheadDesktopLta from "./vue-components/desktop/MastheadDesktopLta";
import MastheadMobileLta from "./vue-components/mobile/MastheadMobileLta";

export default (config) =>
  config.map(({ targetSelector: el, navData, searchData }) => {
    actions.setNavData(navData);
    actions.mergeSiteSearchData(searchData);

    const app = new Vue({
      el,
      name: "MastheadContainer",
      data() {
        return store;
      },
      render(h) {
        const component = this.isDesktop ? MastheadDesktopLta : MastheadMobileLta;

        return h(component);
      },
    });

    return {
      config,
      app,
    };
  });
