<template>
  <div
    class="wrapper bg-grey"
    :style="{
      ...modalStyle,
      zIndex: 9999,
      overflowY: 'scroll',
    }"
  >
    <masthead-mobile-menu-bar-lta />
    <nav class="m-nav--masthead mg-top mg-bottom">
      <h2
        ref="navHeading"
        class="u-screen-reader-only"
        tabindex="0"
        @focus="handleShiftTab"
        @blur="unbindShiftTab"
      >
        Primary navigation
      </h2>
      <ul class="m-nav--masthead__level-1">
        <mobile-nav-primary-item v-for="item in PrimaryItems" :key="item.url" :item="item" />
        <mobile-nav-primary-item v-if="MorePrimaryItems" :item="MoreItems" :show-overview="false" />
        <mobile-nav-primary-item v-for="item in AuxillaryItems" :key="item.url" :item="item" />
      </ul>
    </nav>
  </div>
</template>
<script>
import Mousetrap from "mousetrap";

import modalHelper from "@/js/vue-mixins/modalHelper";
import {
  focusSubscribe,
  focusUnsubscribe,
  offerFocus,
  willAcceptFocus,
} from "@/js/helpers/focus-helper";

import store from "@/components/molecules/masthead/store";

import MobileNavPrimaryItem from "@/components/molecules/masthead/vue-components/mobile/MobileNavPrimaryItem";
import MastheadMobileMenuBarLta from "@/components/lta/masthead-lta/vue-components/mobile/MastheadMobileMenuBarLta";

export default {
  components: {
    MobileNavPrimaryItem,
    MastheadMobileMenuBarLta,
  },
  mixins: [modalHelper],
  data() {
    return {
      PrimaryItems: store.state.navData.PrimaryItems,
      MorePrimaryItems: store.state.navData.MorePrimaryItems,
      AuxillaryItems: store.state.navData.AuxillaryItems,
    };
  },
  computed: {
    MoreItems() {
      return {
        Title: `More`,
        Url: null,
        Children: this.MorePrimaryItems,
      };
    },
  },
  created() {
    this.$nextTick(() => {
      this.preventBodyScroll(true);
      this.scrollPositionApply(0);
      focusSubscribe(this.acceptFocus);
    });
  },
  beforeDestroy() {
    this.allowBodyScroll(true);
    focusUnsubscribe(this.acceptFocus);
  },
  methods: {
    acceptFocus(opts) {
      willAcceptFocus(opts, {
        trigger: "menuButton",
        target: this.$refs.navHeading,
      });
    },
    handleShiftTab() {
      Mousetrap.bind("shift+tab", (nativeEvent) => {
        console.log("trigger offer focus", { nativeEvent, from: "mobileMenu" });
        offerFocus({ nativeEvent, from: "mobileMenu" });
      });
    },
    unbindShiftTab() {
      Mousetrap.unbind("shift+tab");
    },
  },
};
</script>
